@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200&display=swap');

$background:linear-gradient(180deg,rgb(1,13,24) 0%,rgb(15,76,124) 100%);
$headerBackground: rgb(19,45,89);
$primarycolor:white;
$secondarycolor:grey;
$blackcolor:black;
$grey:grey;

body{
// background: $background !important;
width: 100vw !important;
background-repeat: no-repeat;
overflow-x: hidden;
}

.background{
    background: $background !important;
    
}

.loginButton{
    padding: 1px 5px !important;
    border: 1px solid white !important;
    text-align: center !important;
 border-radius: none !important;
}

.header_font{
    font-family: 'Titillium Web', sans-serif !important;
    font-weight: 600 !important;
    font-size: 15px !important;
}

.footer_font{
    font-family: 'Titillium Web', sans-serif !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    color: lightgrey !important;
  margin: 15px auto !important;
}

.btn-main{
    border: 1px solid white  !important;
    padding: 10px 20px !important;
    background: none !important;
    text-align: center !important;
    
}

.noneColor{
    background: rgb(2,19,34) !important;
    
}

.tableHeading{
   font-weight: 600 !important;
   font-size: 15px !important;
}

.secondColor{
    background-color: rgb(11,57,94) !important;
}

.headerColor{
    background: rgb(19,44,87)  !important;

}

.normalColor{
    background: rgb(19,44,87) !important;
    position: fixed !important;
}

.headerNavbar{
    background: none !important;
    position: fixed !important;
    // height: 50px !important;
}

.white{
    color: white !important;
}

p{
    color: white !important;
}

.btn-main{
    // background: rgb(20,45,90) !important;
    background-color: transparent !important;
    color: white !important;
    border: 1px solid white !important;
    border-radius: 10px 0px 10px 0px !important;
}

.bottom-padding{
    padding-bottom: 100px !important;
}
.mainTop{
    position: relative !important;
    margin-top: -120px !important;
    
}

.footerTop{
     position: relative !important;
    margin-top: -350px !important;
    padding: 0px !important;
}

.mainContent{
    margin-top: 200px !important;
}

@media screen and (max-width:1024px){
    .mainTop{
        position: relative !important;
        margin-top: -150px !important;
    }
    
    .footerTop{
         position: relative !important;
        margin-top: -200px !important;
    }
    .footerData{
        // margin-top: -30px !important;
        // padding-bottom: 100px !important;
    }
    .mainContent{
        margin-top: 300px !important;
    }
}



.size-full{
    width: 2040px;
    height: 180vh;
    position: fixed;
    top: 0px;
    right: 10px;
    opacity:0.2 ;
    z-index: -111 !important;
}





.greyColor{
    color: grey !important;
    font-size: 10px !important;
    text-align: left !important;
    font-weight: 600 !important;
}

.white_name{
    font-size: 15px !important;
}

.smallFont{
    font-size:15px !important;
    color: grey !important;
}

.titleSecondFont{
    font-family: 'Titillium Web', sans-serif !important;
    font-size: 25px !important;
    font-weight: 900 !important;
}

.dropDownContainer{
    position: absolute !important;
    color: grey !important;
    background-color: rgb(2,19,34) !important;
    // padding: 0px 20px !important;
    width: 150px !important;
    border-radius: 0px 0px 10px 10px !important;
    top: 100% !important;
    left: -10px !important;
    z-index: 999999888 !important;
margin: 0px !important;
padding: 0px !important;
}

.mobileDropDownContainer{
    background-color: rgb(2,19,34) !important;
    width: 40% !important;
    margin: 0px auto !important;
    border-radius: 10px !important;
    position: absolute !important;
    z-index: 9999999 !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    margin-top: -20px !important;
}


.mobileDropDownContainer>ul{
    font-size: 15px !important;
    text-align: left !important;
    cursor: pointer !important;
    padding:5px  20px !important;
    // transform: translateX(20px);
    // background-color: red !important;
    margin: 0px !important;
    color: white !important;
}



.mobileDropDownContainer>ul:nth-child(1):hover{
    background:rgba(128, 128, 128, 0.281) !important;
    border-radius: 10px 10px 0px 0px !important;
}


.mobileDropDownContainer>ul:last-child:hover{
    background:rgba(128, 128, 128, 0.281) !important;
    border-radius: 0px 0px 10px 10px !important;
}








.dropDownContainer>ul{
    font-size: 15px !important;
    text-align: left !important;
    cursor: pointer !important;
    padding:5px  20px !important;
    // transform: translateX(20px);
    // background-color: red !important;
    margin: 0px !important;
}

.dropDownContainer>ul:hover{
    background:rgba(128, 128, 128, 0.281) !important;
}


.dropDownContainer>ul:last-child:hover{
    background:rgba(128, 128, 128, 0.281) !important;
    border-radius: 0px 0px 10px 10px !important;
}



.line{
    width: 100% !important;
    height: .5px !important;
    background-color: rgba(128, 128, 128, 0.367) !important;

}

.displayWeb{
    display: block !important;
}

.displayNone{
    display: none !important;
}


@media screen and (max-width:700px){
    .displayWeb{
        display: none !important;
    }
    .displayNone{
        display: block !important;
    }
}


// .copyrights{
//     padding-top: 20px !important;
// }

// .footer_container{
//    transform: translateY(10px);
// }

// @media screen and (max-width:1550px){
//     .footer_container{
//         transform: translateY(50px) !important;
//     }
// }



@media screen and (max-width: 1023px) {
 
.hideData{
background:red !important;
display: block !important;
  visibility: hidden !important;
}   
}


// @media screen and (max-width:500px){
//     .footer_container{
//         transform: translateY(100px) !important;
//     }
// }

// @media screen and (max-width:300px){
//     .footer_container{
//         transform: translateY(150px) !important;
//     }
// }


// _::-webkit-full-page-media, _:future, :root  .footer_font{
    
   
//         color:#0000FF !important; 
//         background-color:#CCCCCC !important; 
//          font-size: 100px !important;

  
//   }

// .container{
//     width: 100vw !important;
//    padding: 0px 100px !important;
// }

// .main-container{
//     width: 100vw !important;
// }

// .header-container{
//     position: relative;
//     padding: 0px 100px ;
//     margin: -10px ;
//     z-index: 999999;
// }
// .white{
//     color: $primarycolor !important;
// }
// .grey{
//     color: grey !important;
// }

// .green{
//     color: green !important;
 
// }

// .red{
//     color: red !important;
    
// }


// .black{
//     color: $blackcolor !important;
// }
// .regular{
//     font-family: TTCommons-Regular, sans-serif !important;
//     font-weight: 500 !important;
//     font-size: xx-large;
// }

// .bold{
//     font-family: TTCommons-Bold, sans-serif !important;
//     font-weight: 700 !important;
//     font-size: xx-large;
// }

// .semi-bold{
//     font-family: TTCommons-Bold, sans-serif !important;
//     font-weight: 700 !important;
//     font-size: large;
// }


// .normal{
//     background-color: transparent;
// }
// .headerColor{
//     background-color: rgb(0,33,66);
//     position: fixed;
//     top: 0px;
//     width: 100%;
// }


// .attachment-full{
//     width: 50px;
//     height: 50px;
// }



// .wallex-support-container{
//     width: 100vw;
//     text-align: left;
//     color: $primarycolor;
//     margin: 150px 0px;
  
// }

// strong{
//     font-family: TTCommons-DemiBold, sans-serif;
//     font-weight: 500;
//     font-size: 3vw;
//     width: 100%;
// }

// p{
//     font-family: TTCommons-Regular, sans-serif;
//     font-weight: 500;
//     font-size: 1.5vw;
//     width: 100%;
// }

// .table-container{
//     position: relative;
//     color: $primarycolor;
//     width: 80% !important;
//     z-index: 999;
// }

// .category {
//     width: 300px !important ;
//   }

  

// // .ePjDZf{
// //     display: flex !important;
// //     flex-direction: column !important;
// //     justify-content: center !important;
// //     align-items: center !important;
// //     align-content: center !important;
// //     img{
// //         width: 30px;
// //         height: 30px;
// //     }
// // }

// .table-name{
//     display: flex;
//     align-items: center;
//     justify-content: space-between !important;
// }

// .table-inside{
//     display: flex;
//     align-items: center;
// }

// .table-main-name{
//     display: flex;
//     align-items: center !important;
//     justify-content: space-between;
// }

// .fiat-container{
//     display: grid !important;
//     grid-template-columns: repeat(4, 1fr) !important;
//     padding: 0px 1.5rem !important;
//     margin: 5rem auto !important;
//     box-sizing: border-box !important;

// }

// .fiat-container >div{
//     border-bottom: 1px solid white !important;
//     margin-top: 20px;
// }


// .flex{
//     display: flex;
//     align-items: center;
//     justify-content: space-around;
// }

// li{
//     list-style: none !important;
//     font-family: TTCommons-Regular, sans-serif;
//     font-weight: 900;
//     font-size: x-large;
//     color: grey;
//     margin-top: 50px;
// }

// .follow-row,.advanced{
//     width: 30%;
//     font-family: TTCommons-Regular, sans-serif;
//     font-weight: 900;
//     font-size: medium;
//   margin-top: 50px;
//   margin-left: 60px;
// }

// .social{
//     background-color: white ;
//     border-radius: 100%;
//     font-family: TTCommons-Regular, sans-serif;
//     font-weight: 900;
//     font-size: medium;
// }

// .text-center{
//     text-align: center;
//     width: 80%;
//     margin: 50px auto;
//     margin-bottom: 0px !important;
//     font-family: TTCommons-Regular, sans-serif;
//     font-weight: 400;
//     font-size: small;
// left: 50%;

// }

// .sec-btn{
//     background-color: transparent !important;
//    outline: none !important;
//    border: none !important;
//    padding: 5px !important;
//    border: 1px solid white !important;
//    font-family: TTCommons-Regular, sans-serif;
//     font-weight: 400;
//     font-size: medium;
// }

// .footer{
//     background-color: rgb(0,33,66) !important;
//     margin: -10px;
// }