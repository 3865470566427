
@font-face {
    font-family: "TTCommons-Regular";
    src: url("./TTCommons-Regular.woff2");
    font-display: block;
}

@font-face {
    font-family: "TTCommons-DemiBold";
    src: url("./TTCommons-DemiBold.woff2");
    font-display: block;
}

@font-face {
    font-family: "TTCommons-Light";
    src: url("./TTCommons-Light.woff2");
    font-display: block;
}

@font-face {
    font-family: "TTCommons-ExtraLight";
    src: url("./TTCommons-ExtraLight.woff2");
    font-display: block;
}

@font-face {
    font-family: "TTCommons-Thin";
    src: url("./TTCommons-Thin.woff2");
    font-display: block;
}